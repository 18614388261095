import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const CredentialsFormWrapper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(5, 7, 4),
  backgroundColor: theme.palette.secondary.dark,
  backgroundImage: "none",
  borderTopRightRadius: theme.spacing(12),
}));

export default CredentialsFormWrapper;
