import AbsoluteCenter from "@/components/generic/absoluteCenter";
import { ButtonWrapper } from "@/components/generic/form/buttonWrapper";
import CredentialsFormWrapper from "@/components/generic/form/credentialsFormWrapper";
import SEO from "@/components/generic/seo";
import { useSendPasswordResetLinkMutation } from "@/generated";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link, PageProps } from "gatsby";
import React, { useState } from "react";

type PasswordResetPageProps = PageProps;

const Login: React.FC = () => (
  <Button variant="text" component={Link} to="/login/" color="tertiary">
    Login
  </Button>
);

const PasswordResetPage: React.FC<PasswordResetPageProps> = () => {
  const [username, setUsername] = useState("");
  const [honeypot, setHoneypot] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [sendLink, { loading }] = useSendPasswordResetLinkMutation();

  return (
    <AbsoluteCenter maxWidth="sm">
      <SEO title="Reset je wachtwoord" />
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          if (honeypot === "") {
            if (!username) {
              setError("Je moet een email adres vullen.");
              return;
            }

            const { data } = await sendLink({ variables: { email: username } });

            if (data.sendUserPasswordResetLink) {
              setError("");
              setSuccess(true);
            }
          }
        }}
      >
        <CredentialsFormWrapper elevation={7}>
          {success ? (
            <>
              <Typography variant="h6" color="tertiary" gutterBottom align="center">
                Er is een link verstuurd naar je e-mail adres.
              </Typography>
              <Login />
            </>
          ) : (
            <>
              <Typography variant="h5" component="span">
                Wachtwoord vergeten?
              </Typography>
              <TextField
                type="text"
                value={honeypot}
                onChange={(e) => setHoneypot(e.currentTarget.value)}
                disabled={loading}
                sx={{ display: "none" }}
              />
              <TextField
                value={username}
                label="e-mail"
                onChange={(e) => setUsername(e.currentTarget.value)}
                disabled={loading}
              />
              {error && (
                <Typography variant="caption" component="p" color="error.main">
                  {error}
                </Typography>
              )}
              {loading && (
                <Typography variant="caption" component="p" color="primary.light">
                  Aanvraag in behandeling...
                </Typography>
              )}
              <ButtonWrapper>
                <Login />
                <Button variant="contained" type="submit" disabled={loading}>
                  Reset je wachtwoord
                </Button>
              </ButtonWrapper>
            </>
          )}
        </CredentialsFormWrapper>
      </form>
    </AbsoluteCenter>
  );
};

export default PasswordResetPage;
